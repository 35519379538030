import BGPH1 from '../../../assets/images/backgrounds/page-header-bg-1-1.jpg'
import { Link } from 'react-router-dom'
import React from 'react'

const Service = () => {
    return (
        <>
            <div className="stricky-header stricked-menu main-menu">
                <div className="sticky-header__content"></div>
            </div>
            <section className="page-header">
                <div className="page-header__bg" style={{ backgroundImage: `url(${BGPH1})` }}></div>
                <div className="container">
                    <ul className="thm-breadcrumb list-unstyled">
                        <li><Link to="/">Home</Link></li>
                        <li><span>Services</span></li>
                    </ul>
                    <h2>Our Services</h2>
                </div>
            </section>
            <section className="service-grid pt-120 pb-140">
                <div className="container">
                    <div className="row row-gutter-y-50">
                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <Link to="/servicedetails">
                                <div className="service-card">
                                    <div className="service-card__image">
                                        <img src="assets/images/services/services-1-1.png" alt="Auto Car Loan" />
                                        <Link to="/servicedetails"></Link>
                                    </div>
                                    <div className="service-card__content">
                                        <div className="service-card__content__inner">
                                            <div className="service-card__icon">
                                                <i className="icon-house"></i>
                                            </div>
                                            <h3 className="service-card__title">
                                                <Link to="/servicedetails">HOME LOANS</Link>
                                            </h3>
                                            <p className="service-card__text" style={{ color: "#68686F" }}>At Ozrich Finance, we offer a wide range of home loan products to suit your individual needs. </p>
                                            {/* <Link to="/servicedetails" className="service-card__link">
                                                <i className="fa fa-angle-right"></i>
                                            </Link> */}
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <Link to="/servicedetails2">
                                <div className="service-card">
                                    <div className="service-card__image">
                                        <img src="assets/images/services/services-1-2.png" alt="Wedding Loan" />
                                        <Link to="/servicedetails2"></Link>
                                    </div>
                                    <div className="service-card__content">
                                        <div className="service-card__content__inner">
                                            <div className="service-card__icon">
                                                <i className="icon-diamond"></i>
                                            </div>
                                            <h3 className="service-card__title">
                                                <Link to="/servicedetails2">INVESTMENT PROPERTY LOANS </Link>
                                            </h3>
                                            <p className="service-card__text" style={{ color: "#68686F" }}>Investing in property can be a lucrative venture, and our team specializes in providing tailored investment property loan solutions.
                                            </p>
                                            {/* <Link to="/servicedetails2" className="service-card__link">
                                                <i className="fa fa-angle-right"></i>
                                            </Link> */}
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <Link to="/servicedetails3">

                                <div className="service-card">
                                    <div className="service-card__image">
                                        <img src="assets/images/services/services-1-3.png" alt="Property Loan" />
                                        <Link to="/servicedetails3"></Link>
                                    </div>
                                    <div className="service-card__content">
                                        <div className="service-card__content__inner">
                                            <div className="service-card__icon">
                                                <i className="icon-loan"></i>
                                            </div>
                                            <h3 className="service-card__title">
                                                <Link to="/servicedetails3">REFINANCE </Link>
                                            </h3>
                                            <p className="service-card__text" style={{ color: "#68686F" }}>If you're looking to refinance your current home loan, we can help you find a better deal that suits your changing financial circumstances. </p>
                                            {/* <Link to="/servicedetails3" className="service-card__link">
                                                <i className="fa fa-angle-right"></i>
                                            </Link> */}
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>

                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <Link to="/servicedetails4">

                                <div className="service-card">
                                    <div className="service-card__image">
                                        <img src="assets/images/services/services-1-4.png" alt="Education Loan" />
                                        <Link to="/servicedetails4"></Link>
                                    </div>
                                    <div className="service-card__content">
                                        <div className="service-card__content__inner">
                                            <div className="service-card__icon">
                                                <i className="icon-money-transfer"></i>
                                            </div>
                                            <h3 className="service-card__title">
                                                <Link to="/servicedetails4">BRIDGING LOANS </Link>
                                            </h3>
                                            <p className="service-card__text" style={{ color: "#68686F" }}>When you're buying a new property before selling your existing one, bridging loans can bridge the gap in your finances</p>
                                            {/* <Link to="/servicedetails4" className="service-card__link">
                                                <i className="fa fa-angle-right"></i>
                                            </Link> */}
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <Link to="/servicedetails5">

                                <div className="service-card">
                                    <div className="service-card__image">
                                        <img src="assets/images/services/services-1-5.png" alt="Personal Loan" />
                                        <Link to="/servicedetails5"></Link>
                                    </div>
                                    <div className="service-card__content">
                                        <div className="service-card__content__inner">
                                            <div className="service-card__icon">
                                                <i className="icon-car"></i>
                                            </div>
                                            <h3 className="service-card__title">
                                                <Link to="/servicedetails5">REVERSE MORTGAGE </Link>
                                            </h3>
                                            <p className="service-card__text" style={{ color: "#68686F" }}>A reverse mortgage can be a valuable financial tool for seniors who own their homes and wish to access the equity they've built up</p>
                                            {/* <Link to="/servicedetails5" className="service-card__link">
                                                <i className="fa fa-angle-right"></i>
                                            </Link> */}
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>

                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <Link to="/servicedetails6">

                                <div className="service-card">
                                    <div className="service-card__image">
                                        <img src="assets/images/services/services-1-6.png" alt="Personal Loan" />
                                        <Link to="/servicedetails6"></Link>
                                    </div>
                                    <div className="service-card__content">
                                        <div className="service-card__content__inner">
                                            <div className="service-card__icon">
                                                <i className="icon-user"></i>
                                            </div>
                                            <h3 className="service-card__title">
                                                <Link to="/servicedetails6">PERSONAL LOANS</Link>
                                            </h3>
                                            <p className="service-card__text" style={{ color: "#68686F" }}>When you need funds for personal expenses, unexpected emergencies, or any other purpose, our personal loans can provide the financial assistance you require</p>
                                            {/* <Link to="/servicedetails6" className="service-card__link">
                                                <i className="fa fa-angle-right"></i>
                                            </Link> */}
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>

                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <Link to="/servicedetails7">

                                <div className="service-card">
                                    <div className="service-card__image">
                                        <img src="assets/images/services/service_3.png" alt="Personal Loan" />
                                        <Link to="/servicedetails7"></Link>
                                    </div>
                                    <div className="service-card__content">
                                        <div className="service-card__content__inner">
                                            <div className="service-card__icon">
                                                <i className="icon-bank"></i>
                                            </div>
                                            <h3 className="service-card__title">
                                                <Link to="/servicedetails7">COMMERCIAL PROPERTY LOANS</Link>
                                            </h3>
                                            <p className="service-card__text" style={{ color: "#68686F" }}>For business owners and investors, securing the right financing for commercial properties is essential</p>
                                            {/* <Link to="/servicedetails7" className="service-card__link">
                                                <i className="fa fa-angle-right"></i>
                                            </Link> */}
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>


                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <Link to="/servicedetails8">

                                <div className="service-card">
                                    <div className="service-card__image">
                                        <img src="assets/images/services/service_1.png" alt="Personal Loan" />
                                        <Link to="/servicedetails8"></Link>
                                    </div>
                                    <div className="service-card__content">
                                        <div className="service-card__content__inner">
                                            <div className="service-card__icon">
                                                <i className="icon-briefcase"></i>
                                            </div>
                                            <h3 className="service-card__title">
                                                <Link to="/servicedetails8">BUSINESS LENDING </Link>
                                            </h3>
                                            <p className="service-card__text" style={{ color: "#68686F" }}>We understand that businesses have diverse financial needs at various stages of growth. </p>
                                            {/* <Link to="/servicedetails8" className="service-card__link">
                                                <i className="fa fa-angle-right"></i>
                                            </Link> */}
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>


                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <Link to="/servicedetails9">

                                <div className="service-card">
                                    <div className="service-card__image">
                                        <img src="assets/images/services/service_2.png" alt="Personal Loan" />
                                        <Link to="/servicedetails9"></Link>
                                    </div>
                                    <div className="service-card__content">
                                        <div className="service-card__content__inner">
                                            <div className="service-card__icon">
                                                <i className="icon-settings"></i>
                                            </div>
                                            <h3 className="service-card__title">
                                                <Link to="/servicedetails9">EQUIPMENT FINANCING </Link>
                                            </h3>
                                            <p className="service-card__text" style={{ color: "#68686F" }}>Acquiring or upgrading equipment is often essential for businesses across various industries. </p>
                                            {/* <Link to="/servicedetails9" className="service-card__link">
                                                <i className="fa fa-angle-right"></i>
                                            </Link> */}
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>







                    </div>
                </div>
            </section>
            <Link to="#" data-target="html" className="scroll-to-target scroll-to-top"><i className="fa fa-angle-up"></i></Link>
        </>
    )
}

export default Service
