import BGPH1 from '../../assets/images/backgrounds/page-header-bg-1-1.jpg'
import { Link } from 'react-router-dom'
import React from 'react'

const Careers = () => {
    return (
        <>

            <div className="stricky-header stricked-menu main-menu">
                <div className="sticky-header__content"></div>
            </div>
            <section className="page-header">
                <div className="page-header__bg" style={{ backgroundImage: `url(${BGPH1})` }}></div>

                <div className="container">
                    <ul className="thm-breadcrumb list-unstyled">
                        <li><Link to="/">Home</Link></li>
                        <li><span>FIRST HOME BUYERS</span></li>
                    </ul>
                    <h2>FIRST HOME BUYERS</h2>
                </div>
            </section>
            <section className="about-three text-center pt-120 pb-20">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            {/* <div className="about-three__image mb-60 wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="000ms">
                            <img src="assets/images/resources/about-3-1.png" alt="" />
                        </div> */}
                            <div className="about-three__content">
                                <div className="block-title text-center">
                                    <p className="block-title__tagline">FIRST HOME BUYERS</p>
                                    <h2 className="block-title__title">First Home Buyer Checklist</h2>
                                </div>
                                <p className="about-three__text mt--10">Congratulations on embarking on the exciting journey of becoming a first home buyer! To help you navigate through the process smoothly, we have prepared a comprehensive checklist of key steps and considerations:</p>
                                {/* <div className="about-three__btns mt-40">
                                <Link to="#" className="thm-btn thm-btn--dark-hover">Let’s Get Started</Link>
                            </div> */}

                            </div>

                        </div>
                    </div>
                </div>
            </section>

            <section className="about-five pt-0 pb-120">

                <div className="container">
                    <div className="row row-gutter-y-0">

                        <div className="col-lg-12">
                            <div className="about-five__content">
                                {/* <div className="block-title text-left">
                                    <p className="block-title__tagline">About Company</p>
                                    
                                </div> */}
                                {/* <h3 className="about-five__subtitle">Duis irure dolor lipsum is simply free text available.</h3> */}
                                {/* <p className="about-five__text">Welcome to Ozrich Finance, your trusted partner for all your financial needs in Australia. We specialize in offering a comprehensive range of financial services tailored to meet the unique requirements of our valued clients. Whether you're a first home buyer, an investor, a business owner, or simply looking to refinance, our experienced team is here to assist you every step of the way. </p> */}
                                <div className="team-progress__item">
                                    {/* <h4 className="team-progress__title">FIRST HOME BUYERS </h4> */}
                                    {/* <div className="team-progress__bar">
                                        <div className="team-progress__bar__inner count-bar" data-percent="77%" style={{ width: '77%' }} >
                                            <div className="team-progress__bar__text count-text" >77%</div>
                                        </div>

                                    </div> */}
                                    {/* <p className="about-five__text">Buying your first home is an exciting milestone, and we understand that it can also be overwhelming. Our dedicated team of experts is well-versed in the complexities of the Australian property market and can guide you through the process of securing your dream home. We'll help you navigate the maze of first home buyer grants, eligibility criteria, and loan options, ensuring a smooth and stress-free experience. </p> */}


                                    <ul className="list-unstyled ml-0 about-two__list ">
                                        <li className='mt-4'>
                                            <i className="fa fa-arrow-circle-right"></i>
                                            <b>
                                                Determine your budget
                                            </b>
                                            <br />
                                            Assess your financial situation and determine how much you can comfortably afford for your first home. Consider factors such as your income, savings, and other financial obligations.
                                        </li>

                                        <li className='mt-4'>
                                            <i className="fa fa-arrow-circle-right"></i>
                                            <b>
                                                Research and understand grants and incentives
                                            </b>
                                            <br />
                                            Familiarize yourself with the various government grants and incentives available for first home buyers in Australia. These can include the First Homeowner Grant (FHOG), stamp duty concessions, and more. Make sure you understand the eligibility criteria and application process for each.
                                        </li>

                                        <li className='mt-4'>
                                            <i className="fa fa-arrow-circle-right"></i>
                                            <b>
                                                Get pre-approved for a home loan
                                            </b><br />
                                            Speak with our experienced team to get pre-approved for a home loan. This will provide you with a clear understanding of your borrowing capacity and streamline the house hunting process.

                                        </li>

                                        <li className='mt-4'>
                                            <i className="fa fa-arrow-circle-right"></i>
                                            <b>
                                                Determine your property preferences
                                            </b><br />

                                            Consider your lifestyle, preferred location, and property requirements. Determine whether you're looking for a house, apartment, or townhouse, and make a list of must-have features and amenities.

                                        </li>

                                        <li className='mt-4'>
                                            <i className="fa fa-arrow-circle-right"></i>
                                            <b>
                                                Start house hunting
                                            </b><br />

                                            Begin your search for properties that align with your preferences. Attend open inspections, research online listings, and engage the services of a real estate agent to help you find suitable options.

                                        </li>

                                        <li className='mt-4'>
                                            <i className="fa fa-arrow-circle-right"></i>
                                            <b>
                                                Conduct thorough inspections
                                            </b><br />

                                            When you find a property that interests you, arrange inspections to assess its condition, layout, and suitability. Consider hiring a professional building inspector to identify any potential issues or defects.

                                        </li>
                                        <li className='mt-4'>
                                            <i className="fa fa-arrow-circle-right"></i>
                                            <b>
                                                Engage a conveyancer or solicitor
                                            </b><br />

                                            Once you've found a property and are ready to make an offer, engage the services of a professional conveyancer or solicitor to handle the legal aspects of the purchase. They will guide you through the contract, negotiations, and settlement process.

                                        </li>
                                        <li className='mt-4'>
                                            <i className="fa fa-arrow-circle-right"></i>
                                            <b>
                                                Make an offer and negotiate
                                            </b><br />

                                            Work with your conveyancer or solicitor to make an offer on the property. They will assist you in negotiating the best terms and conditions, including the purchase price, deposit amount, and settlement timeframe.

                                        </li>
                                        <li className='mt-4'>
                                            <i className="fa fa-arrow-circle-right"></i>
                                            <b>
                                                Arrange finance and finalize your home loan
                                            </b><br />

                                            Provide the necessary documentation to your lender and work closely with our team to secure your home loan. Ensure you fully understand the terms and conditions, including interest rates, repayment options, and fees.
                                        
                                    </li>
                                    <li className='mt-4'>
                                        <i className="fa fa-arrow-circle-right"></i>
                                        <b>
                                            Conduct due diligence
                                        </b><br />

                                        Before finalizing the purchase, conduct thorough due diligence, including obtaining a building and pest inspection report, reviewing strata documents (if applicable), and conducting any necessary property searches.

                                    </li>
                                    <li className='mt-4'>
                                        <i className="fa fa-arrow-circle-right"></i>
                                        <b>
                                            Exchange contracts and pay the deposit
                                        </b><br />

                                        Once all parties have agreed on the terms, contracts will be exchanged, and you'll be required to pay the deposit as specified in the contract. Your conveyancer or solicitor will guide you through this process.

                                    </li>
                                    <li className='mt-4'>
                                        <i className="fa fa-arrow-circle-right"></i>
                                        <b>
                                            Prepare for settlement
                                        </b><br />

                                        Work with your conveyancer or solicitor to prepare for settlement. This includes coordinating with your lender, arranging necessary insurance coverage, and ensuring all documentation is in order.

                                    </li>
                                    <li className='mt-4'>
                                        <i className="fa fa-arrow-circle-right"></i>
                                        <b>
                                            Settle and move in
                                        </b><br />

                                        On the settlement day, your conveyancer or solicitor will facilitate the transfer of funds and legal ownership of the property. Once settled, you'll receive the keys to your new home and can begin the exciting process of moving in!

                                    </li>

                                </ul>



                                <p className="about-five__text pt-40">Remember, buying your first home is a significant milestone, and our team at Ozrich Finance is here to support and guide you throughout the entire process. Feel free to contact us for expert advice and personalized assistance tailored to your unique needs.</p>


                            </div>

                            {/* <div className="row row-gutter-y-30">
                                    <div className="col-md-6">
                                        <ul className="list-unstyled ml-0 about-two__list">
                                            <li className='mt-4'>
                                                <i className="fa fa-arrow-circle-right"></i>
                                                <b>
                                                Nsectetur cing elit.
                                            </b>
                                                </li>
                                            <li className='mt-4'>
                                                <i className="fa fa-arrow-circle-right"></i>
                                                <b>
                                                Suspe ndisse suscipit sagittis leo.
                                            </b>
                                                </li>
                                            <li className='mt-4'>
                                                <i className="fa fa-arrow-circle-right"></i>
                                                <b>
                                                Entum estibulum digni posuere.
                                            </b>
                                                </li>
                                            <li className='mt-4'>
                                                <i className="fa fa-arrow-circle-right"></i>
                                                <b>
                                                Donec tristique ante dictum rhoncus.
                                            </b>
                                                </li>
                                        </ul>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="about-five__video">
                                            <img src="assets/images/resources/about-5-v-1.png" alt="" />
                                            <Link to="#" onClick={() => setytShow(true)} className="video-popup about-five__video__btn">
                                                <i className="fa fa-play"></i>
                                                <span className="ripple"></span>
                                            </Link>
                                        </div>
                                    </div>
                                </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </section >

        {/* <section className="career-one pt-120 pb-120">
            <div className="container">
                <div className="block-title text-center">
                    <p className="block-title__tagline">What We’re Offering</p>
                    <h2 className="block-title__title">Checkout finlon new job <br /> opportunities </h2>
                </div>
                <div className="row row-gutter-y-10">
                    <div className="col-lg-12 wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="000ms">
                        <div className="career-one__item">
                            <div className="career-one__icon">
                                <i className="icon-management"></i>
                            </div>
                            <div className="career-one__content">
                                <h3 className="career-one__title"><Link to="/contact">Account Manager</Link></h3>
                                
                                <p className="career-one__text">Proin aliquet sagittis ex quis ultricies. Suspendisse a felis
                                    euismod, faucibus sem nec, porta velit.</p>
                            </div>
                            <div className="career-one__btns">
                                <Link to="/contact" className="thm-btn">Schedule An Appointment</Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-12 wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="100ms">
                        <div className="career-one__item">
                            <div className="career-one__icon">
                                <i className="icon-designer"></i>
                            </div>
                            <div className="career-one__content">
                                <h3 className="career-one__title"><Link to="/contact">UI/UX Designer</Link></h3>
                                
                                <p className="career-one__text">Proin aliquet sagittis ex quis ultricies. Suspendisse a felis
                                    euismod, faucibus sem nec, porta velit.</p>
                            </div>
                            <div className="career-one__btns">
                                <Link to="/contact" className="thm-btn">Schedule An Appointment</Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-12 wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="200ms">
                        <div className="career-one__item">
                            <div className="career-one__icon">
                                <i className="icon-programmer"></i>
                            </div>
                            <div className="career-one__content">
                                <h3 className="career-one__title"><Link to="/contact">Website Developer</Link></h3>
                                
                                <p className="career-one__text">Proin aliquet sagittis ex quis ultricies. Suspendisse a felis
                                    euismod, faucibus sem nec, porta velit.</p>
                            </div>
                            <div className="career-one__btns">
                                <Link to="/contact" className="thm-btn">Schedule An Appointment</Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-12 wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="300ms">
                        <div className="career-one__item">
                            <div className="career-one__icon">
                                <i className="icon-flowchart"></i>
                            </div>
                            <div className="career-one__content">
                                <h3 className="career-one__title"><Link to="/contact">Project Management</Link></h3>
                                
                                <p className="career-one__text">Proin aliquet sagittis ex quis ultricies. Suspendisse a felis
                                    euismod, faucibus sem nec, porta velit.</p>
                            </div>
                            <div className="career-one__btns">
                                <Link to="/contact" className="thm-btn">Schedule An Appointment</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section> */}

    {/* <section className="career-about pb-120">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 text-xl-start text-center">
                            <img src="assets/images/resources/career-about-1-1.jpg" className="img-responsive wow fadeInLeft" data-wow-duration="1500ms" alt="" />
                        </div>
                        <div className="col-lg-6">
                            <div className="career-about__content">
                                <div className="block-title text-left">
                                    <p className="block-title__tagline">About Company</p>
                                    <h2 className="block-title__title">Small business loans for daily expenses</h2>
                                </div>
                                <p className="career-about__highlight">Duis irure dolor lipsum is simply free text available.</p>

                                <p className="career-about__text">There are many variations of passages of lorem ipsum available the
                                    majority have suffered alteration in some form by injected humour. Duis aute irure dolor lipsum
                                    is simply free text available.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}

    <Link to="#" data-target="html" className="scroll-to-target scroll-to-top"><i className="fa fa-angle-up"></i></Link>
        </>
    )
}

export default Careers