import BGPH1 from '../../assets/images/backgrounds/page-header-bg-1-1.jpg'
import { Link } from 'react-router-dom'
import React, { useState } from 'react'
import { postContacts } from '../../Services/API/Post'

const Contact = () => {

    const [msg, setmsg] = React.useState(null)

    const [firstName, setfirstName] = useState("");
    const [subject, setsubject] = useState("");
    const [email, setemail] = useState("");
    const [message, setmessage] = useState("");
    const [mobile, setmobile] = useState("");

    const [errfirstName, seterrfirstName] = useState(false);
    const [errsubject, seterrsubject] = useState(false);
    const [errmessage, seterrmessage] = useState(false);
    const [erremail, seterremail] = useState(false);
    const [errmobile, seterrmobile] = useState("");


    React.useEffect(() => {
        if (msg !== null) {
            setTimeout(() => {
                setmsg(null);
            }, 6000);
        }

    }, [msg])

    const contactUs = (event) => {

        const phoneRegex =
            /^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;


        event.preventDefault();

        const obj = {
            name: firstName,
            email: email,
            subject: subject,
            message: message,
            contactNumber: mobile,
        };

        console.log("obj", obj);

        if (firstName === "") {
            seterrfirstName(true);
        }
        if (subject === "") {
            seterrsubject(true);
        }
        if (email === "") {
            seterremail(true);
        }

        if (message === "") {
            seterrmessage(true);
        }

        if (!phoneRegex.test(mobile)) {
            if (mobile === "") {
                seterrmobile("This field is required");
            } else {
                seterrmobile("Please enter a valid phone number");
            }
        }


        if (
            firstName !== "" &&
            email !== "" &&
            subject !== "" &&
            message !== "" &&
            mobile !== "" &&
            phoneRegex.test(mobile)
        ) {
            postContacts(obj)
                .then((res) => {
                    console.log("res", res);
                    setmsg("Your enquiry has been successfully submitted")

                    setfirstName("");
                    setemail("")
                    setsubject("")
                    setmessage("")

                    // alert("Submited..!!")
                    // this.setState(
                    //   {
                    //     name: "",
                    //     email: "",
                    //     contact:"",
                    //     memberNo:"",
                    //     webSite: "",
                    //     message: "",
                    //     toaster: true,
                    //     msg: "Your enquiry has been successfully submitted",
                    //   }
                    //   //   () => {
                    //   //     setTimeout(this.setState({toaster: false}),4000);
                    //   //   }
                    // );
                })
                .catch((error) => {
                    console.log("error", error);
                    // setmsg(error.response.data.message)
                    // setTimeout(setmsg(null),4000);
                    // this.setState({});
                });

        }



    };

    return (
        <>
            <div className="stricky-header stricked-menu main-menu">
                <div className="sticky-header__content"></div>
            </div>
            <section className="page-header">
                <div className="page-header__bg" style={{ backgroundImage: `url(${BGPH1})` }}></div>
                <div className="container">
                    <ul className="thm-breadcrumb list-unstyled">
                        <li><Link to="/">Home</Link></li>
                        <li><span>Contact</span></li>
                    </ul>
                    <h2>Contact us</h2>
                </div>
            </section>

            <section className="contact-one pt-120 pb-120">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4">
                            <div className="contact-one__content">
                                <div className="block-title">
                                    {/* <p className="block-title__tagline">contact</p> */}
                                    <h2 className="block-title__title">Feel free to get
                                        in touch</h2>
                                </div>
                                <p className="contact-one__text">We encourage you to get in touch with our friendly team to discuss your specific financial needs. You can reach us through the contact form on our website, by phone, or by visiting our conveniently located office in Melbourne. We are ready to answer your questions, provide guidance, and assist you in achieving your financial goals.</p>

                                <p className="contact-one__text mt-3">At Ozrich Finance, we are committed to your financial success and look forward to serving as your trusted partner in Australia. Discover the Ozrich Finance difference today.</p>
                                {/* <div className="contact-one__social">
                                    <Link to="#"><i className="fab fa-twitter"></i></Link>
                                    <Link to="#">
                                    <a href='https://m.facebook.com/people/Ozrich-Finance/100054424890114/' target='_blank' >
                                        <i className="fab fa-facebook"></i>
                                    </a>
                                    </Link>
                                    <Link to="#"><i className="fab fa-pinterest"></i></Link>
                                    <Link to="#"><i className="fab fa-instagram"></i></Link>
                                </div> */}
                            </div>
                        </div>
                        <div className="col-lg-8">
                            <form className="form-one contact-form-validated" onSubmit={contactUs} >
                                <div className="row row-gutter-y-20 row-gutter-x-20">
                                    <div className="col-md-6">
                                        <input type="text" placeholder="Full name" name="name"
                                            value={firstName}
                                            onChange={(e) => {
                                                setfirstName(e.target.value);
                                                seterrfirstName(false);
                                            }}
                                        />
                                        {errfirstName && (
                                            <div className="error" style={{ color: "red" }}>
                                                This field is required
                                            </div>
                                        )}
                                    </div>
                                    <div className="col-md-6">
                                        <input type="email" placeholder="Email address" name="email"
                                            value={email}
                                            onChange={(e) => {
                                                setemail(e.target.value);
                                                seterremail(false);
                                            }} />
                                        {erremail && (
                                            <div className="error" style={{ color: "red" }}>
                                                This field is required
                                            </div>
                                        )}
                                    </div>
                                    <div className="col-md-6">
                                        <input type="text" placeholder="Phone number" name="phone"
                                            value={mobile}
                                            onChange={(e) => {
                                                setmobile(e.target.value);
                                                seterrmobile("");
                                            }}
                                        />
                                        {errmobile && (
                                            <div className="error" style={{ color: "red" }}>
                                                {errmobile}
                                            </div>
                                        )}
                                    </div>
                                    <div className="col-md-6">
                                        <input type="text" placeholder="Subject" name="subject"
                                            value={subject}
                                            onChange={(e) => {
                                                setsubject(e.target.value);
                                                seterrsubject(false);
                                            }}
                                        />
                                        {errsubject && (
                                            <div className="error" style={{ color: "red" }}>
                                                This field is required
                                            </div>
                                        )}
                                    </div>
                                    <div className="col-md-12">
                                        <textarea placeholder="Message" name="message"
                                            value={message}
                                            onChange={(e) => {
                                                setmessage(e.target.value);
                                                seterrmessage(false);
                                            }}
                                        ></textarea>
                                        {errmessage && (
                                            <div className="error" style={{ color: "red" }}>
                                                This field is required
                                            </div>
                                        )}
                                    </div>
                                    {msg && (
                                        <span className="error" style={{ color: "green" }} >{msg}</span>
                                    )}
                                    <div className="col-md-12 d-flex align-items-center">

                                        <button type="submit" className="thm-btn">Send Message</button>

                                        {/* <h6 className="contact-info-one__link pl-20 pr-20">Or</h6> */}

                                        {/* <button type="submit" className="thm-btn2">Schedule Appointment</button> */}
                                        {/* <a href='https://calendly.com/ozrichfinance/30min' className='thm-btn' target='_blank'>Schedule An Appointment</a> */}
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>

            <section className="contact-info-one">
                <div className="container">
                    <div className="row d-flex align-items-center">
                        <div className="col-md-4 col-sm-12">
                            <div className="contact-info-one__item">
                                <div className="contact-info-one__icon">
                                    <i className="icon-telephone"></i>
                                </div>
                                <div className="contact-info-one__content">
                                    <p className="contact-info-one__text">Have any question?</p>
                                    <a className="contact-info-one__link" href="tel:0430458729">0430 458 729</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-12">
                            <div className="contact-info-one__item">
                                <div className="contact-info-one__icon">
                                    <i className="icon-email"></i>
                                </div>
                                <div className="contact-info-one__content">
                                    <p className="contact-info-one__text">Write us email</p>
                                    <a className="contact-info-one__link" href="mailto:info@ozrichfinance.com.au">info@ozrichfinance.com.au</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-12">
                            <div className="contact-info-one__item">
                                <div className="contact-info-one__icon">
                                    <i className="icon-pin"></i>
                                </div>
                                <div className="contact-info-one__content">
                                    <p className="contact-info-one__text">Visit anytime</p>
                                    <a className="contact-info-one__link" href="#">8 Khalil Avenue Dandenong <br /> North VIC 3175</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div className="google-map__default">
                <iframe title="template google map" src="https://maps.google.com/maps?width=600&amp;height=400&amp;hl=en&amp;q=8 Khalil Avenue Dandenong North VIC 3175&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed" className="map__default" allowfullscreen></iframe>
            </div>
            <Link to="#" data-target="html" className="scroll-to-target scroll-to-top"><i className="fa fa-angle-up"></i></Link>

        </>
    )
}

export default Contact