import BGPH1 from '../../assets/images/backgrounds/page-header-bg-1-1.jpg'
import { Link } from 'react-router-dom'
import React, { useState } from 'react'

const Faqs = () => {
    const [clicked, setClicked] = useState(0);
    const faqs = [
        {
            question: "What is the First Homeowner Grant (FHOG), and am I eligible? ",
            answer: "The First Homeowner Grant is a government initiative that provides a one-time financial assistance grant to eligible first home buyers. Eligibility criteria, grant amounts, and application processes vary by state or territory. Our team can help determine if you qualify and assist with the application process."
        },
        {
            question: "What are the benefits of using a finance company for my first home purchase? ",
            answer: "Working with a finance company specialized in first home buyer services provides several advantages, including expert guidance, access to a wide range of loan options, assistance with grants and incentives, and personalized support throughout the process."
        },
        {
            question: "How much deposit do I need to buy my first home? ",
            answer: "Generally, a minimum deposit of 5% to 20% of the property's purchase price is required. However, certain government schemes and incentives may allow single parents to purchase a home with a small deposit. Our team can guide you on the specific requirements based on your circumstances."
        },
        {
            question: "Can I get a home loan if I have a limited credit history or a low credit score? ",
            answer: "While a good credit history and score are generally preferred, some lenders offer home loan options for first home buyers with limited credit history or lower credit scores. Our team can explore suitable lenders and loan options based on your unique circumstances."
        },
        {
            question: "How long does the home loan application process take?  ",
            answer: "The home loan application process can vary depending on factors such as lender requirements, documentation completeness, and market conditions. Generally, it can take anywhere from a few days to a few weeks. Our team aims to streamline the process and provide a timely response."
        },


    ]
    const [clickedOne, setClickedOne] = useState(0);
    const faqsone = [
        {
            question: "Can I get a home loan before finding a property?",
            answer: "Yes, you can get pre-approved for a home loan before finding a property. Pre-approval gives you a clear understanding of your borrowing capacity, allowing you to confidently search for properties within your budget."
        },
        {
            question: "What fees and costs should I expect when buying my first home?",
            answer: "When buying your first home, you may encounter various costs, including government fees (such as stamp duty), lender fees, conveyancing or solicitor fees, inspection fees, and  potentially mortgage insurance. Our team can provide a breakdown of the expected costs based on your specific circumstances."
        },
        {
            question: "Mauris a ipsum id libero sodales dapibus",
            answer: "There are many variations of passages of available but the majority have suffered alteration that some form by injected randomised words which don’t look even as slightly believable now. Phasellus mollis ac augue sed varius. "
        },
        {
            question: "What is the difference between fixed-rate and variable-rate home loans? ",
            answer: "Fixed-rate home loans offer a fixed interest rate for a specific period, providing stability in repayments. Variable-rate home loans have interest rates that fluctuate with market conditions, potentially offering more flexibility. We can help you understand the pros and cons of each option."
        },
        {
            question: "Can I use a guarantor to help me secure a home loan?  ",
            answer: "Yes, having a guarantor—such as a family member—can increase your chances of getting approved for a home loan, especially if you have a limited deposit or low income. Our team can provide guidance on how a guarantor arrangement works and the implications involved."
        },
        {
            question: "How can I calculate my borrowing capacity and estimate my mortgage repayments?  ",
            answer: "Our website features handy financial calculators that can help you estimate your borrowing capacity based on your income, expenses, and other factors. You can also use these calculators to estimate your potential mortgage repayments. Feel free to explore these tools or contact us for personalized assistance."
        },

    ]
    return (
        <>
            <div className="stricky-header stricked-menu main-menu">
                <div className="sticky-header__content"></div>
            </div>
            <section className="page-header">
                <div className="page-header__bg" style={{ backgroundImage: `url(${BGPH1})` }}></div>
                <div className="container">
                    <ul className="thm-breadcrumb list-unstyled">
                        <li><Link to="/">Home</Link></li>
                        <li><span>FAQs</span></li>
                    </ul>
                    <h2>FAQs</h2>
                </div>
            </section>
            <section className="faq-grid pt-120 pb-120">
                <div className="container">
                    <div className="row row-gutter-y-20">
                        <div className="col-lg-6">
                            <div className="accrodion-grp faq-one-accrodion" data-grp-name="faq-one-accrodion-1">
                                {faqs.map((item, index) => (
                                    <div className={`accrodion  wow fadeInUp ${index === clicked && "active"}`} key={index} data-wow-delay="0ms">
                                        <div className="accrodion-title" onClick={() => setClicked(index)}>
                                            <h4>{item.question}</h4>
                                            <span className="accrodion-icon"></span>
                                        </div>
                                        {index === clicked && <div className="accrodion-content pl-20">
                                            <div className="inner">
                                                <p>{item.answer}</p>
                                            </div>
                                        </div>}
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="accrodion-grp faq-one-accrodion" data-grp-name="faq-one-accrodion-1">
                                {faqsone.map((item, index) => (
                                    <div className={`accrodion  wow fadeInUp ${index === clickedOne && "active-item"}`} key={index} data-wow-delay="0ms">
                                        <div className="accrodion-title" onClick={() => setClickedOne(index)}>
                                            <h4>{item.question}</h4>
                                            <span className="accrodion-icon"></span>
                                        </div>
                                        {index === clickedOne && <div className="accrodion-content pl-20">
                                            <div className="inner">
                                                <p>{item.answer}</p>
                                            </div>
                                        </div>}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="faq-form pt-120 pb-120">
                <div className="container">
                    <div className="block-title text-center">
                        <p className="block-title__tagline">contact</p>
                        <h2 className="block-title__title">Feel free to get in touch <br /> with our team</h2>
                    </div>
                    <form action="assets/sendemail.php" className="form-one contact-form-validated">
                        <div className="row row-gutter-y-20 row-gutter-x-20">
                            <div className="col-md-6">
                                <input type="text" placeholder="Full name" name="name" />
                            </div>
                            <div className="col-md-6">
                                <input type="email" placeholder="Email address" name="email" />
                            </div>
                            <div className="col-md-6">
                                <input type="text" placeholder="Phone number" name="phone" />
                            </div>
                            <div className="col-md-6">
                                <input type="text" placeholder="Subject" name="subject" />
                            </div>
                            <div className="col-md-12">
                                <textarea placeholder="Message" name="message"></textarea>
                            </div>
                            <div className="col-md-12 text-center">
                                <button type="submit" className="thm-btn">Send a Message</button>
                            </div>
                        </div>
                    </form>
                </div>
            </section>
            <Link to="#" data-target="html" className="scroll-to-target scroll-to-top"><i className="fa fa-angle-up"></i></Link>
        </>
    )
}

export default Faqs