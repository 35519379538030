import { Link } from 'react-router-dom'
import React from 'react'

const Footer = () => {
    return (
        <>
            <footer className="main-footer">
                <div className="container">
                    <div className="row row-gutter-y-30">
                        <div className="col-xl-5 col-md-6">
                            <div className="footer-widget footer-widget--about">
                                <Link to="/" className="footer-widget__logo">
                                    <img src="assets/images/logo.jpg" alt="finlon" width="220" height="91" />
                                </Link>
                                {/* <p className="footer-widget__text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. In hac
                                habitasse platea dictumst.</p> */}
                                <ul className="list-unstyled footer-widget__info">
                                    <li>
                                        <i className="icon-email"></i>
                                        <a href='mailto:info@ozrichfinance.com.au' >info@ozrichfinance.com.au </a>
                                    </li>
                                    <li>
                                        <i className="icon-telephone"></i>
                                        <a href="tel:0430458729">0430 458 729	</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xl-3 col-md-6">
                            <div className="footer-widget footer-widget--links">
                                <h3 className="footer-widget__title">
                                    Explore
                                </h3>
                                <ul className="list-unstyled footer-widget__menu">
                                    <li><Link to="/about">About</Link></li>
                                    <li><Link to="/service">Our Services</Link></li>
                                    <li><Link to="/calculator">Calculator</Link></li>
                                    {/* <li><Link to="/applynow">Schedule An Appointment</Link></li> */}
                                    <li><Link to="/contact">Contact</Link></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xl-4 col-md-6">
                            <div className="footer-widget footer-widget--time">
                                <h3 className="footer-widget__title">
                                    Opening Hours
                                </h3>
                                <p className="footer-widget__text">
                                    <div className="row row-gutter-y-30">
                                        <div className="col d-flex flex-column"
                                            style={{
                                                maxWidth: "130px"
                                            }}
                                        >

                                            <div>Monday </div>
                                            <div>Tuesday </div>
                                            <div>Wednesday </div>
                                            <div>Thursday </div>
                                            <div>Friday</div>
                                            <div>Saturday</div>
                                            <div>Sunday</div>
                                        </div>
                                        <div className="col d-flex flex-column">

                                            <div>: 9:00am - 9:00pm </div>
                                            <div>: 9:00am - 9:00pm </div>
                                            <div>: 9:00am - 9:00pm </div>
                                            <div>: 9:00am - 9:00pm </div>
                                            <div>: 9:00am - 9:00pm </div>
                                            <div>: 9:00am - 9:00pm </div>
                                            <div>: 9:00am - 9:00pm </div>
                                        </div>
                                    </div>


                                    {/* <br/> Saturday: 9:00am - 5:00pm <br /> Sunday: Closed */}
                                </p>
                                <div className="footer-widget__social">
                                    {/* <Link to="#"><i className="fab fa-twitter"></i></Link> */}


                                    <a href='https://m.facebook.com/people/Ozrich-Finance/100054424890114/' target='_blank' >
                                        <i className="fab fa-facebook"></i>
                                    </a>

                                    {/* <Link to="#"><i className="fab fa-pinterest"></i></Link>
                                <Link to="#"><i className="fab fa-instagram"></i></Link> */}
                                </div>
                            </div>
                        </div>
                        {/* <div className="col-lg-3 col-md-6">
                            <div className="footer-widget footer-widget--newsletter">
                                <h3 className="footer-widget__title">
                                    Newsletter
                                </h3>
                                <form className="footer-widget__mailchimp">
                                    <input type="email" placeholder="Email address" />
                                    <p className="footer-widget__mailchimp__text">
                                        <i className="fa fa-check"></i>
                                        I agree to all your terms and policies
                                    </p>
                                </form>
                            </div>
                        </div> */}
                    </div>
                </div>
            </footer>
            <div className="bottom-footer">
                <div className="container">
                    <p className="bottom-footer__text text-center">Copyright & Design By &ensp;
                        <a href='https://infinitycs.com.au' target='_blank' >
                            <img src="/assets/images/company.png" alt="company" style={{ height: "30px", marginTop: "-5px" }} />
                        </a>

                    </p>

                </div>
            </div>
        </>
    )
}

export default Footer