import BGPH1 from '../../../assets/images/backgrounds/page-header-bg-1-1.jpg'
import BGS1 from '../../../assets/images/services/service-s-1-1.png'
import { Link } from 'react-router-dom'
import React, { useState } from 'react'

const ServiceDetails = () => {
    const [clicked, setClicked] = useState(0);
    const faqs = [
        {
            question: "Nunc dui massa, porttitor id erat et",
            answer: "There are many variations of passages the majority have suffered alteration in some fo injected humour, or randomised words believable. Phasellus a rhoncus erat."
        },
        {
            question: "Quisque quis urna consequat, scelerisque",
            answer: "There are many variations of passages the majority have suffered alteration in some fo injected humour, or randomised words believable. Phasellus a rhoncus erat."
        },
        {
            question: "Mauris a ipsum id libero sodales dapibus",
            answer: "There are many variations of passages the majority have suffered alteration in some fo injected humour, or randomised words believable. Phasellus a rhoncus erat.There are many variations of passages of available but the majority have suffered alteration that some form by injected randomised words which don’t look even as slightly believable now. Phasellus mollis ac augue sed varius. "
        },
        {
            question: "Nunc dui massa, porttitor id erat et",
            answer: "There are many variations of passages the majority have suffered alteration in some fo injected humour, or randomised words believable. Phasellus a rhoncus erat."
        }
    ]
    return (
        <>
            <div className="stricky-header stricked-menu main-menu">
                <div className="sticky-header__content"></div>
            </div>
            <section className="page-header">
                <div className="page-header__bg" style={{ backgroundImage: `url(${BGPH1})` }}></div>
                <div className="container">
                    <ul className="thm-breadcrumb list-unstyled">
                        <li><Link to="/">Home</Link></li>
                        <li><span>Services</span></li>
                    </ul>
                    <h2>Services Details</h2>
                </div>
            </section>
            <section className="service-details pt-120 pb-120">
                <div className="container">
                    <div className="row row-gutter-y-30">
                        <div className="col-lg-4">
                            <div className="service-sidebar">
                                <div className="service-sidebar__item service-sidebar__item--menu">
                                    <ul className="service-sidebar__menu">
                                        <li ><Link to="/servicedetails">HOME LOANS </Link></li>
                                        <li ><Link to="/servicedetails2">INVESTMENT PROPERTY LOANS </Link></li>
                                        <li ><Link to="/servicedetails3">REFINANCE </Link></li>
                                        <li ><Link to="/servicedetails4">BRIDGING LOANS </Link></li>
                                        <li className="active"><Link to="/servicedetails5">REVERSE MORTGAGE </Link></li>
                                        <li><Link to="/servicedetails6">PERSONAL LOANS  </Link></li>
                                        <li><Link to="/servicedetails7">COMMERCIAL PROPERTY LOANS  </Link></li>
                                        <li><Link to="/servicedetails8">BUSINESS LENDING </Link></li>
                                        <li><Link to="/servicedetails9">EQUIPMENT FINANCING </Link></li>
                                    </ul>
                                </div>
                                <div className="service-sidebar__item service-sidebar__item--contact">
                                    <div className="service-sidebar__bg" style={{ backgroundImage: `url(${BGS1})` }}>
                                    </div>
                                    <div className="service-sidebar__contact">
                                        <div className="service-sidebar__icon">
                                            <i className="icon-phone-ringing"></i>
                                        </div>
                                        <h3 className="service-sidebar__title">Quick loan
                                            proccess</h3>
                                        <hr className="service-sidebar__separator" />
                                        <p className="service-sidebar__tagline">Talk to an expert</p>
                                        <a href='tel:0430458729' className="service-sidebar__phone">0430 458 729</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-8">
                            {/* <div className="service-details__image">
                                <img src="assets/images/services/service-d-1-1.png" alt="" />
                                <div className="service-details__icon">
                                    <i className="icon-diamond"></i>
                                </div>
                            </div> */}
                            <div className="service-details__content">
                                <h3 className="service-details__title">REVERSE MORTGAGE </h3>
                                <p>A reverse mortgage can be a valuable financial tool for seniors who own their homes and wish to access the equity they've built up. Our team can provide comprehensive guidance on reverse mortgages, ensuring you have a clear understanding of the benefits and implications. We'll help you unlock the value of your home while allowing you to maintain ownership and residency.</p>
                                <p>List of documents typically required for the loan. These documents may vary depending on the lender, loan type, and individual circumstances. It's always best to consult with us for the most accurate and up-to-date information regarding the required documentation.</p>

                                <div className="col-md-12 pt-20">

                                    <ul className="list-unstyled ml-0 service-details__list">
                                        <li>
                                            <i className="fa fa-check-circle"></i>
                                            <b>
                                                Proof of identity
                                            </b>
                                        </li>
                                        <li>
                                            <i className="fa fa-check-circle"></i>
                                            <b>
                                                Proof of age (usually 60 years or older)
                                            </b>
                                        </li>
                                        <li>
                                            <i className="fa fa-check-circle"></i>
                                            <b>
                                                Proof of property ownership (e.g., title deed)
                                            </b>
                                        </li>
                                        <li>
                                            <i className="fa fa-check-circle"></i>
                                            <b>
                                                Existing mortgage documents (if applicable)
                                            </b>
                                        </li>

                                    </ul>
                                </div>



                                {/* <div className="row row-gutter-y-30">
                                    <div className="col-md-6">
                                        <img src="assets/images/services/service-d-1-2.png" alt="" />
                                    </div>
                                    <div className="col-md-6">
                                        <h3 className="service-details__subtitle">Service benefits</h3>
                                        <p className="service-details__text">Duis aute irure dolor in reprehenderit in voluptate velit
                                            esse cillum.</p>
                                        <ul className="list-unstyled ml-0 service-details__list">
                                            <li>
                                                <i className="fa fa-check-circle"></i>
                                                <b>
                                                Refresing to get such a personal touch.
                                            </b>
                                                </li>
                                            <li>
                                                <i className="fa fa-check-circle"></i>
                                                <b>
                                                Duis aute irure dolor in in voluptate.
                                            </b>
                                                </li>
                                            <li>
                                                <i className="fa fa-check-circle"></i>
                                                <b>
                                                Velit esse cillum eu fugiat pariatur.
                                            </b>
                                                </li>
                                            <li>
                                                <i className="fa fa-check-circle"></i>
                                                <b>
                                                Duis aute irure dolor in in voluptate.
                                            </b>
                                                </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="accrodion-grp service-details__accrodion" data-grp-name="service-details__accrodion-1">
                                            {faqs.map((item, index) => (
                                                <div className={`accrodion  wow fadeInUp ${index === clicked && "active"}`} key={index} data-wow-delay="0ms">
                                                    <div className="accrodion-title" onClick={() => setClicked(index)}>
                                                        <h4>{item.question}</h4>
                                                        <span className="accrodion-icon"></span>
                                                    </div>
                                                    {index === clicked && <div className="accrodion-content">
                                                        <div className="inner">
                                                            <p>{item.answer}</p>
                                                        </div>
                                                    </div>}
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Link to="#" data-target="html" className="scroll-to-target scroll-to-top"><i className="fa fa-angle-up"></i></Link>
        </>
    )
}

export default ServiceDetails