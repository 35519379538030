import BGPH1 from '../../assets/images/backgrounds/page-header-bg-1-1.jpg'
import { Link, NavLink, useNavigate } from 'react-router-dom'
import React from 'react'

const Careers = () => {

    let history = useNavigate()

    return (
        <>

            <div className="stricky-header stricked-menu main-menu">
                <div className="sticky-header__content"></div>
            </div>
            <section className="page-header">
                <div className="page-header__bg" style={{ backgroundImage: `url(${BGPH1})` }}></div>

                <div className="container">
                    <ul className="thm-breadcrumb list-unstyled">
                        <li><Link to="/">Home</Link></li>
                        <li><span>Calculator</span></li>
                    </ul>
                    <h2>Calculator</h2>
                </div>
            </section>
            <section className="about-three text-center pt-120 pb-20">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            {/* <div className="about-three__image mb-60 wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="000ms">
                            <img src="assets/images/resources/about-3-1.png" alt="" />
                        </div> */}
                            <div className="about-three__content">
                                {/* <div className="block-title text-center">
                                    <p className="block-title__tagline">Calculator</p>
                                    <h2 className="block-title__title">Calculator</h2>
                                </div> */}
                                <p className="about-three__text mt--10">Our website features a range of financial calculators designed to assist you in making informed decisions. These calculators cover various aspects of finance, including home loan affordability, mortgage repayments, borrowing capacity, and more. By utilizing these tools, you can gain valuable insights and estimate your financial commitments before making any decisions.</p>
                                {/* <div className="about-three__btns mt-40">
                                <Link to="#" className="thm-btn thm-btn--dark-hover">Let’s Get Started</Link>
                            </div> */}

                            </div>

                        </div>
                    </div>
                </div>
            </section>



            <section className="career-one pt-20 pb-120">
                <div className="container">
                    {/* <div className="block-title text-center">
                    <p className="block-title__tagline">What We’re Offering</p>
                    <h2 className="block-title__title">Checkout finlon new job <br /> opportunities </h2>
                </div> */}
                    <div className="row row-gutter-y-10">


                        <div className="col-lg-12 wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="000ms">

                        <a href='http://module50.visionabacus.com/Tools/B3/SuiteA/Z200/MFAA/Home_Loan_Offset_Calculator/' target='_blank' >
                                <div className="career-one__item">
                                    <div className="career-one__icon">
                                        <i className="icon-management"></i>
                                    </div>
                                    <div className="career-one__content">
                                        <a href='http://module50.visionabacus.com/Tools/B3/SuiteA/Z200/MFAA/Home_Loan_Offset_Calculator/' target='_blank' >
                                            <h3 className="career-one__title">HOME LOAN CALCULATOR</h3>
                                        </a>

                                        <p className="career-one__text" style={{ color: "#68686F" }}>Buying your first home? Or refinancing your existing
                                            loan? Our home loan calculator gives you the option
                                            to try out different loan amounts with on your de-
                                            sired payment frequency, giving you the ability to
                                            make a concise decision that's comfortable and af-
                                            fordable based on your lifestyle.</p>
                                    </div>
                                    {/* <div className="career-one__btns">
                                    <Link to="/contact" className="thm-btn">Schedule An Appointment</Link>
                                </div> */}
                                </div>
                            </a>
                        </div>

                        <div className="col-lg-12 wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="100ms">

                        <a href='http://module50.visionabacus.com/Tools/B3/SuiteA/Z200/MFAA/Saving_Calculator/' target='_blank' >

                                <div className="career-one__item">
                                    <div className="career-one__icon">
                                        <i className="icon-designer"></i>
                                    </div>
                                    <div className="career-one__content">

                                        <a href='http://module50.visionabacus.com/Tools/B3/SuiteA/Z200/MFAA/Saving_Calculator/' target='_blank' >
                                            <h3 className="career-one__title">SAVINGS TARGET </h3>
                                        </a>

                                        <p className="career-one__text" style={{ color: "#68686F" }}>Saving to buy your dream home can be intimidating
                                            and stressful, especially if you're a first-time home
                                            buyer. Our online savings target calculator will assist
                                            you to identify how much you need to save weekly,
                                            fortnightly or monthly to achieve your savings target.</p>
                                    </div>
                                    {/* <div className="career-one__btns">
                                    <Link to="/contact" className="thm-btn">Schedule An Appointment</Link>
                                </div> */}
                                </div>
                            </a>
                        </div>
                        <div className="col-lg-12 wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="200ms">

                        <a href='http://module50.visionabacus.com/Tools/B3/SuiteA/Z200/MFAA/Stamp_Duty_Calculator/' target='_blank' >

                                <div className="career-one__item">
                                    <div className="career-one__icon">
                                        <i className="icon-programmer"></i>
                                    </div>
                                    <div className="career-one__content">

                                        <a href='http://module50.visionabacus.com/Tools/B3/SuiteA/Z200/MFAA/Stamp_Duty_Calculator/' target='_blank' >
                                            <h3 className="career-one__title">STAMP DUTY CALCULATOR</h3>

                                        </a>

                                        <p className="career-one__text" style={{ color: "#68686F" }}>Whether you're buying a property for residential pur-
                                            poses or simply for investment, we believe it's im-
                                            portant that you consider the costs associated in
                                            stamp duty & other government fees so that you can
                                            factor them into your budget prior to making your in-
                                            vestment decision.</p>
                                    </div>
                                    {/* <div className="career-one__btns">
                                    <Link to="/contact" className="thm-btn">Schedule An Appointment</Link>
                                </div> */}
                                </div>
                            </a>
                        </div>
                        <div className="col-lg-12 wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="300ms">
                            <a href='http://module50.visionabacus.com/Tools/B3/SuiteA/Z200/MFAA/Comparison_Rate_Calculator/' target='_blank' >

                                <div className="career-one__item">
                                    <div className="career-one__icon">
                                        <i className="icon-flowchart"></i>
                                    </div>
                                    <div className="career-one__content">
                                        <a href='http://module50.visionabacus.com/Tools/B3/SuiteA/Z200/MFAA/Comparison_Rate_Calculator/' target='_blank' >
                                            <h3 className="career-one__title">INTEREST COMPARISON</h3>

                                        </a>

                                        <p className="career-one__text" style={{ color: "#68686F" }}>Weighing up different loan options can be confusing,
                                            especially when you're trying to make a decision on
                                            which loan is more budget friendly and best suits
                                            your lifestyle. Not sure which option works for you?
                                            Our online interest comparison calculator shows you
                                            the interest.</p>
                                    </div>
                                    {/* <div className="career-one__btns">
                                    <Link to="/contact" className="thm-btn">Schedule An Appointment</Link>
                                </div> */}
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </section>

            {/* <section className="career-about pb-120">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 text-xl-start text-center">
                            <img src="assets/images/resources/career-about-1-1.jpg" className="img-responsive wow fadeInLeft" data-wow-duration="1500ms" alt="" />
                        </div>
                        <div className="col-lg-6">
                            <div className="career-about__content">
                                <div className="block-title text-left">
                                    <p className="block-title__tagline">About Company</p>
                                    <h2 className="block-title__title">Small business loans for daily expenses</h2>
                                </div>
                                <p className="career-about__highlight">Duis irure dolor lipsum is simply free text available.</p>

                                <p className="career-about__text">There are many variations of passages of lorem ipsum available the
                                    majority have suffered alteration in some form by injected humour. Duis aute irure dolor lipsum
                                    is simply free text available.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}

            <Link to="#" data-target="html" className="scroll-to-target scroll-to-top"><i className="fa fa-angle-up"></i></Link>
        </>
    )
}

export default Careers