import React from 'react';
// import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { hydrate, render } from "react-dom";
import ReactDOM from "react-dom";

// const root = ReactDOM.createRoot(document.getElementById('root'));
const rootElement = document.getElementById("root");

// if (rootElement.hasChildNodes()) {
//   hydrate(

//     <React.StrictMode>
//       <App />
//     </React.StrictMode>
//     );
// } else {
//   root.render(
//     <React.StrictMode>
//       <App />
//     </React.StrictMode>
//   );
// }

// if (rootElement.hasChildNodes()) {
//   hydrate(
//     <App />
//    ,
//     rootElement);
// } else {
//   render(
//     <App />
//     , rootElement);
// }

render(
  <App />
  , rootElement);

// root.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
